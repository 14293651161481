/* eslint-disable no-alert */
/* eslint-disable no-console */

const syncUserSettings = async () => {
  try {
    const response = await fetch("/settings/sync-user-settings", { method: "POST" });

    switch (response.status) {
      case 201:
      case 409: {
        const results = await response.json();

        const { environment } = window.gnabb;
        const isCloudEnv = environment !== "development" && environment !== "test";

        document.cookie = [
          `user_settings=${encodeURIComponent(JSON.stringify(results))}`,
          "Path=/",
          "Max-Age=31536000",
          "SameSite=Lax",
          isCloudEnv ? "Secure;" : "",
        ].join("; ");
      }
    }
  } catch (error) {
    console.error("Something went wrong while syncing user-settings with server");
    console.error(error);
  }
};

if ("requestIdleCallback" in window) {
  window.requestIdleCallback(syncUserSettings);
} else {
  window.setTimeout(syncUserSettings, 500);
}
