/* eslint-disable no-alert */
// @ts-check
export class FeedbackButtons extends HTMLElement {
  connectedCallback() {
    this.articleTitle = this.getAttribute("article-title") || "";
    this.path = this.getAttribute("article-path") || null;
    this.uuid = this.getAttribute("article-id") || "";
    this.addEventListener("click", this);
  }
  /**
   * @param {Event} event
   */
  handleEvent(event) {
    if (event.type === "click") {
      const target = /** @type {HTMLElement} */(event.target);
      const button = target.closest("button");
      if (!button) {
        return;
      }
      const feedback = /** @type {'like' | 'dislike' | undefined} */(button.dataset.feedback);

      this.toggleButtons(button);

      const url = this.path ? window.location.hostname + this.path : null;
      this.sendFeedback({ type: feedback, uuid: this.uuid, title: this.articleTitle, url });
    }
  }

  /**
   * @param {HTMLButtonElement} pressedButton
   */
  toggleButtons(pressedButton) {
    const buttons = this.querySelectorAll("button");
    buttons.forEach((button) => {
      if (button !== pressedButton) {
        button.removeAttribute("aria-pressed");
      } else {
        button.setAttribute("aria-pressed", "true");
      }
    });
  }
  /**
   * Sends feedback to Slack via webhook.
   *
   * Takes partial props for feedback, since it's a bit of a pain
   * to make the stuff picked from DOM typed properly.
   *
   * @param {Partial<import("../../../lib/routes/send-feedback.js").Feedback>} feedbackProps
   */
  sendFeedback({ type, uuid, title, url }) {
    /** @type {string} */
    let textFeedback = "";

    const addText = confirm("Tack för det!\nVill du lämna nån skriftlig kommentar kring nyhetssammanfattningen?\nHelt anonymt och hanteras endast internt på Bonnier News.");
    if (addText) {
      textFeedback = (prompt("Vad skulle du vilja tillägga?") || "");
    }

    // Strip out characters that control Slack formatting
    const forbidden = new RegExp(/[_*~]/g);
    textFeedback = textFeedback.replaceAll(forbidden, "");
    fetch("/send-feedback", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        feedback: {
          type,
          text: textFeedback,
          uuid,
          title,
          url,
        },
      }),
    }).then((response) => {
      if (response.ok && response.status === 200) {
        if (textFeedback) {
          alert("Tack för din feedback! ❤️");
        }
      } else {
        alert("Något gick fel, försök igen senare. 😢");
      }
    });
  }
}
