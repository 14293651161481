// @ts-check
import HTMLParsedElement from "../helpers/html-parsed-element.js";
import { throttle } from "../helpers/throttle.js";

/**
 * A custom element to control a horizontal scroller using prev/next buttons,
 * that activate/deactivate based on the scroll position.
 *
 * Inspired by this article:
 * @see https://www.aleksandrhovhannisyan.com/blog/image-carousel-tutorial/
 */

export default class ScrollerController extends HTMLParsedElement {
  parsedCallback() {
    if (!window.gnabb.scrollerControllerEnabled) {
      return;
    }
    this.classList.add("scroller-controller");
    this.handleScroll = throttle(this.handleScroll, 200);
    this.navigationControls = /** @type {HTMLTemplateElement | null} */(this.querySelector("template[data-scroller-controller-controls]"));
    this.scrollContainer = /** @type {HTMLElement} */(this.querySelector("[data-scroller]"));

    if (!(this.navigationControls && this.scrollContainer)) {
      return;
    }

    this.pagingWidth = this.scrollContainer.clientWidth;
    this.appendChild(this.navigationControls.content.cloneNode(true));
    this.navButtonNext = this.querySelector("button[data-direction='next']");
    this.navButtonPrev = this.querySelector("button[data-direction='prev']");
    this.scrollContainer.addEventListener("scroll", this);
    this.addEventListener("click", this);
    this.updateScrollState();
    this.setupResizeHandle();

    this.activeSource = /** @type {HTMLElement} */(this.querySelector("a[aria-current]"));

    if (this.activeSource) {
      // Remember current scroll-behavior and set it to "auto" to instantly scroll to the active pill.
      const prevScrollBehavior = this.scrollContainer.style.scrollBehavior;
      this.scrollContainer.style.scrollBehavior = "auto";
      this.scrollContainer.scrollLeft = this.activeSource.offsetLeft;
      // Revert to previous scroll-behavior
      this.scrollContainer.style.scrollBehavior = prevScrollBehavior;
    }
  }

  /**
   * @param {Event} event
   */
  handleEvent(event) {
    if (event.type === "scroll") {
      this.handleScroll();
    }
    if (event.type === "click") {
      this.handleClick(event);
    }
  }

  setupResizeHandle() {
    if (typeof window.ResizeObserver !== "function") {
      // Fall back to throttled resize event for older browsers, e.g. Safari < 15
      window.addEventListener("resize", throttle(() => {
        if (!this.scrollContainer) {
          return;
        }
        this.pagingWidth = this.scrollContainer.clientWidth;
        this.updateScrollState();
      }, 250));
    }
    const resizeObserver = new ResizeObserver((entries) => {
      // Only one entry is expected.
      const [ box ] = entries;
      // Element should not have fragments, so treat as one box.
      const [ size ] = box.contentBoxSize;
      this.pagingWidth = size.inlineSize;
      this.updateScrollState();
    });
    resizeObserver.observe(this);
  }

  handleScroll() {
    this.updateScrollState();
  }

  updateScrollState() {
    if (!(this.scrollContainer && this.navButtonPrev && this.navButtonNext)) {
      return;
    }
    // scrollLeft is negative in a right-to-left writing mode
    const scrollLeft = Math.abs(this.scrollContainer.scrollLeft);
    // off-by-one correction for Chrome, where clientWidth is sometimes rounded down
    const width = this.scrollContainer.clientWidth + 1;
    const isAtStart = Math.floor(scrollLeft) === 0;
    const isAtEnd = Math.ceil(width + scrollLeft) >= this.scrollContainer.scrollWidth;
    this.navButtonPrev.setAttribute("aria-disabled", String(isAtStart));
    this.navButtonNext.setAttribute("aria-disabled", String(isAtEnd));
  }

  /**
   * @param {Event} event
   * @returns
   */
  handleClick(event) {
    /** @type {HTMLButtonElement | null} */
    const button = /** @type {HTMLElement} */(event.target).closest("button[data-direction]");
    if (!button) {
      return;
    }
    const direction = /** @type {"prev" | "next" | undefined} */(button.dataset.direction);
    const isDisabled = button.getAttribute("aria-disabled") === "true";
    if (isDisabled) {
      return;
    }
    if (direction) {
      this.navigateToNextPage(direction);
    }
  }

  /**
   * @param {'prev'|'next'} direction
   */
  navigateToNextPage(direction) {
    if (!(this.scrollContainer && this.pagingWidth)) {
      return;
    }
    const scrollAmount = this.pagingWidth * (direction === "prev" ? -1 : 1);
    const maxScroll = this.scrollContainer.scrollWidth - this.pagingWidth;
    this.scrollContainer.scrollLeft = Math.min(
      Math.max(0, this.scrollContainer.scrollLeft + scrollAmount), maxScroll
    );
  }
}
