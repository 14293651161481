import HTMLParsedElement from "../helpers/html-parsed-element.js";

let getRecommendations = function () {
  const promise = new Promise((resolve, reject) => {
    fetch("/recommendations").then((response) => {
      if (response.ok) {
        resolve(response.json());
      } else {
        reject();
      }
    });
  });
  getRecommendations = () => promise;
  return promise;
};

export class RecommendedTeaser extends HTMLParsedElement {
  async parsedCallback() {
    try {
      const position = this.getAttribute("position");
      const skeleton = this.querySelector(".article-teaser");

      const loadedRecommendations = await getRecommendations();
      const recommendation = loadedRecommendations[position - 1];

      if (recommendation) {
        const params = new URLSearchParams(recommendation).toString();
        const response = await fetch(`/teaser/?${params}`);

        if (response.ok) {
          const data = await response.json();
          if (document.querySelector(`[data-reference-url="${data.url}"]`)) {
            throw Error("Item already in feed");
          }

          if (data?.markup) {
            skeleton.remove();
            this.insertAdjacentHTML("beforeend", data.markup);
          }
        } else {
          throw new Error("Couldn't fetch teaser");
        }
      } else {
        throw new Error("No data for position");
      }
    } catch {
      this.remove();
    }
  }
}
