// @ts-check
window.didomiConfig = window.didomiConfig || {};
window.didomiOnReady = window.didomiOnReady || [];
window.didomiEventListeners = window.didomiEventListeners || [];

/** @typedef {import('../../lib/@types/didomi-types').IDidomiObject} Didomi */

export const initDidomiPreferences = () => {
  window.addEventListener("click", (e) => {
    if (e.target instanceof HTMLElement &&
        e.target.hasAttribute("data-didomi-preftoggle")) {
      window.didomiOnReady?.push((/** @type {Didomi} */ Didomi) => {
        Didomi.notice.show();
      });
    }
  });
};
